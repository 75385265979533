@import "../../../assets/styles/themes";
@import "../../../assets/styles/placeholders";

.Header {
  position: relative;
  padding: 1em 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;

  & > * {
    @extend %hotFadeInOutAnimation;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 3px;
    margin: 0 auto;
    border-radius: 3px;
    background-color: var(--accent-100);
  }

  &-backBtn {
    position: absolute;
    top: 50%;
    left: .5em;
    transform: translateY(-50%);
    padding: 1em;
    width: 70px;
    max-width: 45px !important;
    max-height: 45px !important;
    cursor: pointer;

    svg g {
      stroke: var(--accent-0);
    }
  }

  &-nav {
    z-index: 999;
    background-color: var(--main-200);
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding: 1em;
    left: 100%;
    transform: translateX(0%);
    transition: transform .3s ease;
    box-shadow: 1px 2px 5px 0 rgba(0,0,0, 0.5);
    overflow-y: auto;
    &,
    &-backdrop {
      position: fixed;
      top: 0;
    }

    &-backdrop {
      display: none;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: var(--semi-transparent-black-100);
      z-index: 998;
    }

    &-icon {
      cursor: pointer;
      position: absolute;
      width: 30px;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);

      &:before,
      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 5px;
        border-top: 2px solid var(--accent-100);
        border-bottom: 2px solid var(--accent-100);
      }

      &:before {
        margin-bottom: 5px;
      }

      & .notification-mark{
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 2px solid var(--contrast-0);
        box-sizing: content-box;
        background-color: var(--error-color);
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        z-index: 1;
        @extend %hotFadeInOutAnimation;
      }
    }

    &-item {
      margin: .5em 0;
      font-size: 1.2em;
      font-weight: 600;
      text-align: left;
      color: var(--contrast-0);

      & .notification-mark-number{
        background-color: var(--accent-300);
        color: var(--contrast-0);
        min-width: 25px;
        height: 25px;
        border-radius: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 1em;
        font-size: .75em;
        font-weight: 400;
        padding: .15em .3em;
      }
      &:hover {
        color: var(--accent-300);
      }

      &.activeLink{
        color: var(--accent-300);
      }
    }

    &-avatar {
      margin: 1em auto;
      max-width: 100px !important;
      max-height: 100px !important;
    }

    &-logout {
      font-family: var(--base-font-family);
      margin-top: 1em !important;
      font-size: 1.1em !important;
      height: 40px;
      color: var(--black-400);
      background: var(--white);
    }
  }

  #burger-menu-controller {
    position: fixed;
    left: -100%;
    top: 0;
  }

  #burger-menu-controller:checked ~ &-nav {
    transform: translateX(-100%);

    &-backdrop {
      display: initial;
      background-color: var(--semi-transparent-black-200);
    }
  }

  &-section {
    margin: .8em auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    &-avatar {
      @extend %hotFadeInOutAnimation;
      max-width: 45px;
      max-height: 45px;
    }
    &-name {
      @extend %hotFadeInOutAnimation;
      color: var(--accent-0);
      font-weight: 800;
      font-size: clamp(1.4rem, 3vw, 1.8rem);
      overflow: hidden;
      line-height: 1.3;
      text-transform: uppercase;
      &:empty {
        display: inline-block;
        min-height: 20px;
      }
    }
  }
}
