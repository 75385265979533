.HotFooter {
  a {
    color : unset;
  }

  &-footer {
    background: #000;
    & .container {
      padding: 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;
      font-family: var(--font-family-Dino-regular);
      font-size: 0.9em;
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &-logo,
    &-nav,
    .copyright {
      @media (min-width: 768px) {
        width: 33.3%;
      }
    }

    &-logo {
      margin-bottom: 1.8em;
      & img {
        height: 38px;
      }

      @media (min-width: 768px) {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .copyright {
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 767px) {
        width: 125px;
      }
    }
    .separator {
      @media (max-width: 767px) {
        display: none;
      }
      margin: 0.3em 1em 0 1em;
      display: block;
      background: #fff;
      height: 1em;
      width: 1px;
    }
    &-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 250px;
      margin-bottom: 1em;
      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &-item:not(:first-child) {
        //margin-left: 2rem;
      }

      &-item {
        line-height: 18px;
        &:hover::after {
          width: 100%;
          transition: width 0.3s ease;
        }

        &:after {
          content: "";
          display: block;
          height: 3px;
          margin-top: 0.5em;
          background: var(--pink-100);
          width: 0;
          transition: width 0.3s ease;
        }
      }
    }
  }
}