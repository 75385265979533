@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: "DINosaur-Bold";
  src: url("../../assets/fonts/DINosaur-Bold.otf") format("opentype");

}
@font-face {
  font-family: "DINosaur-Regular";
  src: url("../../assets/fonts/DINosaur-Regular.otf") format("opentype");
}
