.HotNavbar {
  background : #fdfeff;

  a {
    color : unset;
  }

  &-header {
    position   : fixed;
    left       : 0;
    right      : 0;
    top        : 0;
    background : #ffffff;
    z-index    : 3;
    box-shadow : 0 1px 1px 0 rgba(0, 0, 0, 0.03);

    & > .container {
      padding         : 1.5em 2em;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      @media (min-width : 768px) {
        padding : 1.5em 1em;
      }
    }

    &-signin-btn {
      display       : none;
      color         : var(--grey-150) !important;
      min-width     : 95px;
      min-height    : 51px;
      border        : 1px solid #e9eff7;
      border-radius : 100px;
      transition    : 0.2s ease;

      &:hover {
        color      : var(--white);
        box-shadow : 0 10px 34px 0 rgba(23, 128, 224, 0.2);
        transition : 0.2s ease;
      }

      @media (min-width : 768px) {
        display         : flex;
        align-items     : center;
        text-align      : center;
        justify-content : center;
        margin-right    : 0.5em;
      }
    }

    &-play-btn {
      color           : #000000 !important;
      padding         : 0;
      font-size       : 1em;
      line-height     : 21px;
      margin-right    : 1em;
      background      : none;
      font-family     : var(--font-family-Dino-bold);
      text-align      : center;
      display         : flex;
      align-items     : center;
      justify-content : center;

      & .Icon {
        max-width : 40px;
      }

      @media screen and (min-width : 768px) {
        margin-right     : 0;
        background-image : linear-gradient(90deg, #700b81 0%, #f80066 100%);
        border-radius    : 53.13px;
        box-shadow       : 0 9px 22px 0 rgba(112, 144, 176, 0.2);
        border           : none;
        font-size        : 1.1em;
        line-height      : 24px;
        color            : #ffffff !important;
        padding          : 0.6em 1.7em 0.6em 1em;
      }
    }

    &-right-block {
      display     : flex;
      align-items : center;
    }

    #menu_checkbox {
      width                 : 40px;
      height                : 32px;
      position              : absolute;
      right                 : 21px;
      top                   : 50%;
      transform             : translateY(-50%);
      cursor                : pointer;

      opacity               : 0;
      z-index               : 2;

      -webkit-touch-callout : none;
    }

    .Humburger-menu > span:first-child {
      transform-origin : 0% 0%;
    }

    .Humburger-menu > span:nth-last-child(2) {
      transform-origin : 0% 100%;
    }

    .Humburger-menu > span {
      display          : block;
      width            : 22px;
      height           : 0.12em;
      margin-bottom    : 4px;
      background       : #000000;
      border-radius    : 5px;
      z-index          : 1;
      transform-origin : 3px 0;
      transition       : transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        //background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
      opacity 0.55s ease;
    }

    .Humburger-btn.open ~ span {
      opacity    : 1;
      transform  : rotate(45deg) translate(-2px, -1px);
      background : #000000;
    }

    .Humburger-btn.open ~ span:nth-last-child(3) {
      opacity   : 0;
      transform : rotate(0deg) scale(0.2, 0.2);
    }

    .Humburger-btn.open ~ span:nth-last-child(2) {
      transform : rotate(-45deg) translate(0, -1px);
    }

    &-menu-desk {
      display        : none;
      align-items    : center;
      color          : var(--blue-500);
      font-size      : 1em;
      letter-spacing : 0;
      list-style     : none;


      .dropdown {
        position: relative;
        display: inline-block;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        box-shadow: 0 3px 5px 0 rgba(77, 77, 77, 0.2);
        z-index: 1;

        width: 200px;
        border-radius: 5px;

        left: -25px;
        margin-top: 15px;
        padding: 10px 0;

        &-item {
          padding: 10px 29px;
        }
      }

      .dropbtn {
        height: 40px;
        margin-bottom: -20px;
      }

      .dropdown:hover .dropdown-content {
        display: block;
      }

      &-item {
        font-weight    : bold;
        text-transform : uppercase;
        //padding-bottom: .45em;
        cursor         : pointer;
        position       : relative;

        &:hover::after {
          width      : 100%;
          transition : width 0.3s ease;
        }

        &:after {
          position   : absolute;
          content    : "";
          display    : block;
          height     : 3px;
          margin-top : 0.5em;
          background : var(--pink-100);
          width      : 0;
          transition : width 0.3s ease;
        }

        &:not(:last-child) {
          margin-right : 40px;
          @media screen and (min-width : 1920px) {
            margin-right : 72px;
          }
        }

        line-height    : 19px;
      }

      @media screen and (min-width : 768px) {
        display : flex;
      }
    }

    .backdrop {
      display    : none;
      position   : fixed;
      left       : 0;
      top        : 78px;
      height     : calc(100% - 78px);
      right      : 0;
      z-index    : 3;
      background : rgba(255, 255, 255, 0.6);

      &.open {
        display : block;
      }
    }

    &-menu-mobile {
      padding    : 1em 2em;
      background : #ffffff;
      animation  : animContainer 0.3s ease;
      box-shadow : 0 32px 39px 0 rgba(190, 190, 190, 0.27);
      height     : 350px;
      z-index    : 6;
      @media screen and (min-width : 768px) {
        display : none;
      }

      &-header {
        font-size     : 14px;
        color         : var(--blue-500);
        border-bottom : 1px solid #ebecf0;

        &-item {
          cursor          : pointer;
          padding         : 13px 0;
          opacity         : 1;
          display         : flex;
          align-items     : center;
          justify-content : space-between;
          font-weight     : bold;
          transition      : transform 0.3s ease;
          text-transform  : uppercase;

          &:hover {
            transform  : scaleX(0.95);
            transition : transform 0.3s ease;
          }

          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              animation : animListItems $i * 0.2s ease;
            }
          }
        }
      }

      &-footer {
        padding   : 5px 0;
        font-size : 12px;
        color     : #b3bac5;

        &-item {
          @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
              animation : animListItems $i * 0.2s ease;
            }
          }
          transition     : all 0.3s ease;
          text-transform : uppercase;

          &:hover {
            transform  : scaleX(0.95);
            transition : all 0.3s ease;
            color      : var(--blue-500);
          }

          display        : block;
          padding        : 5px 0;
          opacity        : 1;
        }
      }
    }
  }

  &-logo {
    cursor            : pointer;
    min-width         : 79px;
    min-height        : 38px;
    background-image  : url("../../assets/img/landingpage/Logo_Short_HealthOverTime.png");
    @media screen and (min-width : 768px) {
      min-width        : 120px;
      min-height       : 55px;
      background-image : url("../../assets/img/landingpage/Logo_Full_HealthOverTime.png");
    }
    @media screen and (min-width : 1110px) {
      min-width  : 179px;
      min-height : 81px;
    }
    background-size   : contain;
    background-repeat : no-repeat;
  }

  .Humburger-menu {
    padding-top: 0.6em;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}