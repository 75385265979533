:root {
  --white: #fff;
  --white-100: #f6f5f4;

  --red-0: #f88c41;
  --red-100: #FF7942;
  --red-150: #FF7452;
  --red-200: #e64747;
  --red-300: #802828;

  --pink-0: #ffdfdf;
  --pink-50: #FFA8A5;
  --pink-100: #FF487F;

  --violet-100: #A559F9;

  --yellow: #F8E71B;

  --green-100: #B1E83A;
  --green-150: #85d056;
  --green-200: #66BF39;
  --green-250: #62af31;
  --green-300: #408020;
  --green-350: #029219;
  --green-400: #287F19;

  --blue-100: #6ED1EC;
  --blue-200: #36A3D8;
  --blue-300: #205980;
  --blue-500: #1D334F;

  --grey-0: #dcdcdc;
  --grey-50: #cacaca;
  --grey-100: #a5adba;
  --grey-150: #8993A4;
  --grey-200: #6e6e6e;
  --grey-300: #434343;

  --black-blue-500: #22252b;

  --black-100: #2d2d2d;
  --black-200: #1e1e1e;

  --black-300: #020602;
  --black-400: #000;

  --error-color: var(--red-200);
  //fonts
  --font-50: .5rem;
  --font-100: .6rem;
  --font-150: .8rem;
  --font-base: 1rem;
  --font-250: 1.2rem;
  --font-300: 1.3rem;
  --font-400: 1.5rem;
  //border-radius

  --b-radius-30: .25rem;
  --b-radius-50: .5rem;
  --b-radius-200: 1rem;
  --b-radius-300: 2rem;
  --b-radius-round: 50%;

  --base-font-family: "Signika", sans-serif;
  --font-family-Dino-bold: "DINosaur-Bold", sans-serif;
  --font-family-Dino-regular: "DINosaur-Regular", sans-serif;

  --gradient-black: linear-gradient(-179deg, #101010 0%, #343434 100%);

  --transparent: rgba(0, 0, 0, 0);
  --semi-transparent-black-100: rgba(0, 0, 0, 0.20);
  --semi-transparent-black-200: rgba(0, 0, 0, 0.35);
  --semi-transparent-black-300: rgba(0, 0, 0, 0.5);
  --semi-transparent-white_0: rgba(255, 255, 255, 0.10);
  --semi-transparent-white: rgba(255, 255, 255, 0.3);
  --semi-transparent-white-100: rgba(255, 255, 255, 0.5);
  --semi-transparent-white-200: rgba(255, 255, 255, 0.75);

  //transitions
  --transition-ease-200: 0.2s ease;
}

.light-Atmos {
  --accent-0: var(--black-400);
  --accent-100: var(--black-200);
  --accent-150: var(--blue-100);
  --accent-200: var(--blue-200);
  --accent-250: var(--blue-100);
  --accent-300: var(--semi-transparent-black-200);
  //fonts
  --contrast-0: var(--white);
  --contrast-100: var(--grey-0);
  --contrast-200: var(--grey-50);
  //background
  --main-100: var(--blue-100);
  --main-200: var(--blue-200);
  --main-300: var(--blue-300);

  --error-color: var(--red-300);
}

.light-Unknown,
.light-Capra {
  --accent-0: var(--black-400);
  --accent-100: var(--black-200);
  --accent-150: var(--green-100);
  --accent-200: var(--green-200);
  --accent-250: var(--green-300);
  --accent-300: var(--semi-transparent-black-200);
  //fonts
  --contrast-0: var(--white);
  --contrast-100: var(--grey-0);
  --contrast-200: var(--grey-50);
  //background
  --main-100: var(--green-100);
  --main-200: var(--green-200);
  --main-300: var(--green-300);

  --error-color: var(--red-300);
}
.light-Mavric {
  --accent-0: var(--black-400);
  --accent-100: var(--black-200);
  --accent-150: var(--red-100);
  --accent-200: var(--red-200);
  --accent-250: var(--red-100);
  --accent-300: var(--semi-transparent-black-200);
  //fonts
  --contrast-0: var(--white);
  --contrast-100: var(--grey-0);
  --contrast-200: var(--grey-50);
  //background
  --main-100: var(--red-100);
  --main-200: var(--red-200);
  --main-300: var(--red-300);

  --error-color: var(--red-300);
}

.dark-Unknown,
.dark-Capra {
  --accent-0: var(--green-150);
  --accent-100: var(--green-150);
  --accent-150: var(--green-100);
  --accent-200: var(--green-250);
  --accent-250: var(--green-250);
  --accent-300: var(--green-200);
  //fonts
  --contrast-0: var(--grey-0);
  --contrast-100: var(--grey-0);
  --contrast-200: var(--grey-50);
  //background
  --main-100: var(--grey-300);
  --main-200: var(--black-100);
  --main-300: var(--black-300);

  --semi-transparent-black-100: var(--semi-transparent-white_0);
  --semi-transparent-black-200: var(--semi-transparent-white);
}
.dark-Atmos {
  --accent-0: var(--blue-100);
  --accent-100: var(--blue-100);
  --accent-150: var(--blue-100);
  --accent-200: var(--blue-200);
  --accent-250: var(--blue-200);
  --accent-300: var(--blue-200);
  //fonts
  --contrast-0: var(--grey-0);
  --contrast-100: var(--grey-0);
  --contrast-200: var(--grey-50);
  //background
  --main-100: var(--grey-300);
  --main-200: var(--black-100);
  --main-300: var(--black-300);

  --semi-transparent-black-100: var(--semi-transparent-white_0);
  --semi-transparent-black-200: var(--semi-transparent-white);
}
.dark-Mavric {
  --accent-0: var(--red-200);
  --accent-100: var(--red-200);
  --accent-150: var(--red-100);
  --accent-200: var(--red-200);
  --accent-250: var(--red-200);
  --accent-300: var(--red-200);
  //fonts
  --contrast-0: var(--grey-0);
  --contrast-100: var(--grey-0);
  --contrast-200: var(--grey-50);
  //background
  --main-100: var(--grey-300);
  --main-200: var(--black-100);
  --main-300: var(--black-300);

  --semi-transparent-black-100: var(--semi-transparent-white_0);
  --semi-transparent-black-200: var(--semi-transparent-white);
}
