.ErrorBoundary {
  padding: 3.2em 0.8em 0;
  &-details{
    padding-top: 30px;
  }
  &-title {
    line-height: normal;
    font-size: 4em;
    margin-bottom: 0.5em;
  }
  &-text {
    font-size: 1.2em;
    margin-bottom: 1.5em;
  }

  &-header {
    text-align: center;
    margin-bottom: 1em;

  }
  &-reload-btn {
    margin: 0 auto;

  }
  &-stack {
    overflow: auto;
  }
}
