@import "../../../assets/styles/themes";

.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.45em;
  width: 100%;
  max-width: 30px;
  max-height: 30px;
  position: relative;

  &-img.PagesLinksList-link-icon {
    fill: var(--contrast-0) !important;
  }
  &-img  {

    width: 100%;
    max-width: 100%;
    max-height: 100%;

  }
}

