@import "themes";
@import "animations";

%separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.9em;

  &:before,
  &:after {
    display: block;
    content: "";
    flex: 1 1 20%;
    margin: 0 1em;
    height: 1px;
    background-color: var(--semi-transparent-white);
  }
}

%btn {
  background-color: var(--accent-300);
  border: 1px solid transparent;
  color: var(--contrast-0);
  border-radius: 6px;
  font-weight: bold;
  font-size: 1.25em;
  cursor: pointer;
  width: 100%;
  max-width: 230px;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease;

  &:disabled {
    background-color: var(--grey-200);
    border: 1px solid var(--grey-100);
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &.empty {
    background-color: transparent;
    border: 1px solid var(--accent-300);
    color: var(--contrast-0);
    &:hover {
      background-color: var(--accent-300);
    }
  }

  &:active {
    box-shadow: 0 0 0 3px var(--black-300);
  }

  &.small {
    width: auto;
    max-width: initial;
    display: inline-block;
    padding: 0.5em 1em;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 4px;
  }

  &.tiny {
    @extend .small;
    padding: 0.15em 0.5em;
    font-weight: 400;
    border-radius: 3px;

    &:disabled {
      background-color: var(--grey-100);
      border: none;
    }
  }

  &.secondaryRounded {
    @extend .tiny;
    padding: 0.15em 1.25em;
    border-radius: 30px;
  }
  &:hover {
    filter: brightness(1.1);
  }
}

%btngroup {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: transparent;
    color: var(--contrast-100);
    font-size: 0.8em;
    font-weight: 600;
    border-radius: 0;

    &:active {
      background-color: var(--semi-transparent-black-200);
      color: var(--contast-100);
      box-shadow: none;
    }
  }

  button + button {
    border-left: none;
  }

  button:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }

  button:nth-last-child(1) {
    border-radius: 0 3px 3px 0;
  }
}

%page {
  width: 100%;
  margin: 0 auto;
  @extend %hotFadeInOutAnimation;
}

%tableRowDefaults {

  td {
    height: 65px;
    padding: 0.35em 0;
    font-weight: 600;
  }

  td:nth-child(1) {
    %tableCellDefaults {
      border-radius: 5px 0 0 5px;
    }
  }

  td:nth-last-child(1) {
    %tableCellDefaults {
      border-radius: 0 5px 5px 0;
    }
  }

  &:nth-child(odd) %tableCellDefaults {
    color: var(--contrast-0);
    background-color: var(--semi-transparent-black-100);
  }

  &:nth-child(even) %tableCellDefaults {
    color: var(--contrast-0);
    background-color: var(--semi-transparent-black-200);
  }
}

%tableCellDefaults {
  padding: 0.5em 0.75em;
  height: 100%;
  display: flex;
  align-items: center;

}

%fullHeight {
  flex: 1 0 100%;
  height: 100%;
}

.no-image {
  display: block;
  width: 45px;
  height: 45px;
  opacity: 0.8;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;

  &:before,
  &:after {
    content: "";
    width: 50%;
    height: 10%;
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: var(--contrast-0);
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid var(--semi-transparent-white-200);
  object-fit: cover;
}

%hover-effect {
  &:hover {
    filter: contrast(120%);
  }
}

%purple-button {
  background-image: linear-gradient(90deg,#700B81 0%, #F80066 100%);
  border-radius: 53.13px;
  border: none;
  color: var(--white);
  padding: .8em 1em;
  font-family: var(--font-family-Dino-bold);
  &:disabled {
    border: none;
    filter: grayscale(70%);
  }
}
%ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


%Card {
  position: relative;
  font-size: var(--font-base);
  padding: 2em 1em;
  width: 100%;
  max-width: 100%;
  background-color: var(--semi-transparent-black-100);
  min-height: 140px;
  border-left: 4px solid;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  border-radius: var(--b-radius-50);
  @extend %hotFadeInOutAnimation;
}
%bordered-block {
  padding: .2em .9em .2em 0;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: var(--b-radius-200);
  background: var(--semi-transparent-black-200);
  font-size: .9em;
  color: var(--white);
  transition: background-color .3s ease;
  .Icon {
    max-width: 38px;
  }
  &:hover {
    background-color: var(--semi-transparent-black-100);
    transition: background-color .3s ease;
  }
}

%required-star {
  &.required {
    &:after {
      position: absolute;
      right: -10px;
      top: 0;
      display: block;
      content: "*";

      font-size: 15px;
      font-weight: 300;
      color: var(--red-200);
    }
  }
}

%page-wrapper {
  padding: 1em 1em;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--black-100);
}

%fullscreen-map {
  position: fixed;
  width: 95%;
  height: 85vh;
  top: 2em;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    z-index: 999;
    top: 0;
  }
}

%map-loader {
  max-width: 100px;
  max-height: 100px;
  margin: 2em auto 0 auto;
}

%map-message {
  & .hint {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  &:hover {
    .hint {
      visibility: visible;
      opacity: 1;
      position: absolute;
      z-index: 4;
      padding: 0.5em;
      color: var(--black-300);
      background-color: var(--white);
      box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .3);
      font-size: 1.2em;
    }
  }
}
