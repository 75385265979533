@import "../../assets/styles/placeholders";

.MainLayout {
  max-width: 1110px;
  margin: 0 auto;
  min-height: 100vh;
  @extend %hotFadeInOutAnimation;

  &-main {
    @extend %hotFadeInOutAnimation;
    width: 100%;
    min-width: 320px;
    margin: 0 auto 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1em 7em;
    @media (min-width: 1110px){
      padding: 0 0 7em;
    }
  }

  &-footer {
    @extend %hotFadeInOutAnimation;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    max-width: 1110px;
    width: 100%;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-300);
    filter: drop-shadow(0px 4px 5px var(--black-300));
    padding: 1em;
  }
}
