.Footer-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
  .Icon {
    padding: 0;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.activeLink {
      .invite-icon {
        background: var(--white);
        border-radius: 50%;
        svg.invite {
          #icon-invite {
            stroke-width: 3px;
            stroke: black;
          }
        }
      }
    svg.board-nav {
      g {
        fill: #fff;
        stroke: black;
      }
    }
    svg.settings, svg.statistics, svg.dashboard{
        g {
          stroke: var(--black-400);
        }
        path {
          fill: var(--white);
        }
      }

    }
  }
  &-name {
    color: var(--white);
    font-size: .9em;
    @media (min-width: 768px) {
      font-size: 1em;
    }
  }
}
