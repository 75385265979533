button {
  @extend %btn;
}
.lined {
  overflow: hidden;
  position: relative;
  padding: 1em;
  margin: 0;
  font-weight: 700;
  border: none;
  max-height: 70px;
  background: var(--white);
  box-shadow: 0 0 27px 3px rgba(0, 0, 0, .2);
  color: var(--black-400);
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 576px) {
    font-size: 1.4em;
  }
  border-radius: 6px;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 45%;
    height: .2em;
    background: var(--accent-200);
  }
  &:before {
    bottom: 0;
    border-radius:.6em .6em 0 0 ;
  }
  &:after {
    top: 0;
    border-radius: 0 0 .6em .6em;
  }
}
.purple {
  font-family: "DINosaur-Bold", sans-serif;
  @extend %purple-button;
}
