.HotJoinSection {
  &-Join {
    padding: 9em 0 4em 0;
    .HotJoinSection-Join-info-appStore-title {
      @media (min-width: 768px) {
        width: 135%;
      }
    }
    @media screen and (min-width: 1110px) {
      display: flex;
      padding: 12rem 0 8rem 0;
    }
    .container {
      @media screen and (min-width: 768px) {
        display: flex;
        //flex: 1 1 100%;
      }
    }

    &-info {
      text-align: center;
      @media screen and (min-width: 768px) {
        text-align: left;
        flex-basis: 57%;
      }
      @media screen and (min-width: 1110px) {
        padding-top: 1em;
      }

      &-title {
        color: var(--blue-500);
        font-size: 2em;
        line-height: 39px;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        margin-bottom: 0.5em;
        @media screen and (min-width: 768px) {
          width: 135%;
        }
        @media screen and (min-width: 1110px) {
          width: 115%;
          font-size: 3.3em;
          line-height: 60px;
        }
      }

      &-description {
        font-size: 1em;
        line-height: 24px;
        font-family: var(--font-family-Dino-regular);
        color: var(--blue-500);
        margin-bottom: 1.1em;
        @media screen and (min-width: 1110px) {
          font-size: 1.33em;
          line-height: 27.12px;
        }
      }

      &-play-btn {
        color: var(--white) !important;
        width: 100%;
        text-transform: uppercase;
        line-height: 19px;
        font-size: 1em;
        max-width: 360px;
        letter-spacing: -0.17px;
        box-shadow: 0 10px 34px 0 rgba(23, 128, 224, 0.27);
        margin: 0 auto 1.7em auto;
        min-height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 768px) {
          margin: 0 0 1.7em 0;
          max-width: 379px;
        }
        @media screen and (min-width: 1110px) {
          max-width: 500px;
          font-size: 1.3em;
          line-height: 34px;
        }
      }
      &-shop {
        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-title {
          word-break: break-word;
        }
        &-img {
          background: var(--white);
          border-radius: var(--b-radius-round);
          height: 63px;
          width: 63px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 60px;
          & .Icon {
            max-width: 47px;
          }
        }
        &-details {
          @media (min-width: 768px) {
            max-width: 330px;
          }
          @media (min-width: 1110px) {
            max-width: 395px;
          }
        }
      }
      &-appStore,
      &-shop {
        text-align: center;
        @media screen and (min-width: 768px) {
          text-align: left;
          display: flex;
          align-items: center;
        }

        &-img {
          max-width: 123px;
          margin-bottom: 0.7em;
          @media screen and (min-width: 768px) {
            max-width: 144px;
            margin-right: 1.2em;
            margin-bottom: 0;
          }
          @media screen and (min-width: 1110px) {
            max-width: 180px;
          }
        }

        &-details {
          font-size: 0.85em;
        }

        &-title {
          color: var(--blue-500);
          line-height: 16px;
          @media (min-width: 1110px) {
            line-height: 24px;
            font-size: 1.2rem;
          }
          @media (min-width: 768px) {
            line-height: 22px;
            font-size: 1.1rem;
          }
        }

        &-description {
          //letter-spacing: -0.28px;
          color: #001500;
          line-height: 16px;
          font-family: var(--font-family-Dino-regular);
          @media screen and (min-width: 1110px) {
            font-size: 1.29em;
            line-height: 24px;
            width: 110%;
          }
          @media (min-width: 768px) {
            line-height: 19px;
          }
        }
      }
    }

    &-trailer {
      position: relative;
      text-align: center;
      & img {
        margin: 4em auto 0;
        max-height: 320px;
        width: auto;
        filter: drop-shadow(55px 25px 50px rgba(23, 128, 224, 0.3));
        @media (min-width: 400px) {
          max-height: 390px;
        }
        @media (min-width: 768px) {
          margin: 0 auto;
          max-height: 390px;
        }
        @media (min-width: 1110px) {
          max-height: 500px;
        }
      }

      &-watch {
        position: absolute;
        bottom: -8%;
        left: 48%;
        transform: translateX(-50%);
        text-align: center;
        @media screen and (min-width: 400px) {
          bottom: -4%;
          left: 47%;
        }
        @media screen and (min-width: 1110px) {
          bottom: 0;
          //left: 53%;
        }
      }

      &-description {
        color: var(--blue-500);
        line-height: 29.12px;
        font-size: 1em;
        font-family: var(--font-family-Dino-regular);
        @media screen and (min-width: 1110px) {
          font-size: 1.33em;
        }
      }

      &-btn {
        background: #ffffff;
        box-shadow: 0 2px 49px 0 rgba(23, 128, 224, 0.27);
        max-width: 76px;
        max-height: 76px;
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease;
        }

        @media screen and (min-width: 768px) {
          max-width: 55px;
          max-height: 55px;
        }
        @media screen and (min-width: 1110px) {
          max-width: 76px;
          max-height: 76px;
        }
      }
    }
  }

  &-Join-footer-background { }

  &-Join-bottom {
    &:first-child {
      padding-top: 3em;
    }

    @media (min-width: 1110px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .HotJoinSection-Join-info {
      padding: 2.1em 0 2.9em 0;

      &-appStore {
        flex-direction: column;
        text-align: center;

        &-details {
          padding-bottom: 2rem;
        }

        &-description {
          width: 100%;
        }
      }
    }
    .title {
      text-align: center;
      color: var(--blue-500);
      font-size: 2em;
      line-height: 37px;
      margin-bottom: 2rem;
      @media (min-width: 1110px) {
        font-size: 2.2em;
        line-height: 38px;
      }
    }

    &.image {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      min-width: 300px;
      @media (min-width: 1110px) {
        width: 50%;
      }

      &.qr-code {
        padding: 0 1.5em 2em;
      }

      & img {
        max-height: 300px;
        max-width: 100%;
        @media (min-width: 1110px) {
          max-height: 400px;
        }
      }
    }
  }
}