.Loader {
  min-width: 64px;
  min-height: 64px;
  max-width: 150px;
  max-height: 150px;

  svg {
    width: 100%;
    height: 100%;
  }
}
