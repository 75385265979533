@import "src/assets/styles/breakpoints";

.WelcomePage-Quotes {
  margin: 2em 0;
  background-image: url("../../../assets/img/landingpage/reviews-background@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
  .container {
    padding-top: 2.3em;
    padding-bottom: 5.4em;

    .title {
      font-size: 2em;
      line-height: 1.2em;
      margin-bottom: .5rem;
      background: transparent;
      @media (min-width: $breakpoint-laptop) {
        font-size: 2.2em;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media (min-width: $breakpoint-laptop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &-about {
    display: none;
    &-img {
      max-width: 65%;
    }

    @media (min-width: $breakpoint-laptop) {
      display: block;
      width: 30%;
    }
  }

  &-subtitle {
    line-height: 2.3em;
    //margin-bottom: .6rem;
    font-size: 2.1em;
  }

  &-text {
    font-size: 0.9em;
    line-height: 1.5em;
    font-family: var(--font-family-Dino-regular);
  }

  &-slider {
    @media (min-width: $breakpoint-laptop) {
      display: block;
      width: 70%;
    }
    .wr {
      padding: 0.5em 0;
    }
    .slick-list {
      @media (min-width: $breakpoint-mobile-min) {
        height: 325px !important;
      }
      @media (min-width: $breakpoint-mobile-portrait) {
        height: 275px !important;
      }
      @media (min-width: $breakpoint-mobile-landscape) {
        height: 250px !important;
      }
      @media (min-width: $breakpoint-tablet-portrait) {
        height: 325px !important;
      }
      @media (min-width: $breakpoint-tablet-landscape) {
        height: 300px !important;
      }
    }
    .arrow {
      position: absolute;
      //background: red;
      bottom: -19%;
      border-radius: var(--b-radius-round);
      display: flex;
      align-items: center;
      justify-content: center;

      & .Icon {
        max-width: 47px;
        max-height: 47px;
        cursor: pointer;
        circle,
        path {
          transition: --transition-ease-200;
        }
        &:hover {
          circle {
            transition: --transition-ease-200;
            fill: var(--violet-100);
          }

          & path {
            transition: --transition-ease-200;
            fill: var(--white);
          }
        }
      }
    }

    .slick-prev-arrow {
      right: 50%;
      //transform: translateX(-100%);
    }

    .slick-next-arrow {
      right: 50%;
      transform: translateX(100%);
    }

    @media (min-width: $breakpoint-laptop) {
      .slick-prev-arrow {
        right: 8%;
      }
      .slick-next-arrow {
        right: 8%;
      }
    }

    &-content {
      padding: 2em;
      background: var(--violet-100);
      border-radius: var(--b-radius-300);
      //box-shadow: 0 4px 14px 0 rgba(224,229,238,0.50);
      @media (min-width: $breakpoint-tablet-portrait) {
        margin: 0 1.5em;
      }

      &-description {
        font-family: var(--font-family-Dino-regular);
        font-size: var(--font-base);
        color: var(--white);
        line-height: 1.5em;
      }

      &-header {
        &-img,
        picture {
          margin-right: 0.7em;
        }

        &-img {
          border-radius: var(--b-radius-round);
        }

        display: flex;
        align-items: center;
        margin-bottom: 1em;
        color: var(--white);

        &-name,
        &-subtitle {
          font-family: var(--font-family-Dino-regular);
          line-height: 1.4em;
        }

        &-name {
          font-size: 1em
        }

        &-subtitle {
          font-size: 0.9rem;
        }
      }
    }
  }
}