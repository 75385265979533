@import "../../assets/styles/placeholders";

.container {
  font-family: var(--font-family-Dino-regular);
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  @media screen and (min-width: 1110px) {
    max-width: 1110px;
  }

  h1,h2,h3,h4,h5,h6,b,strong,li {
    font-family: var(--font-family-Dino-bold);
  }
}

.WelcomePage {
  background: #fdfeff;

  &-Join {
    padding: 9em 0 4em 0;
    .WelcomePage-Join-info-appStore-title {
      @media (min-width: 768px) {
        width: 135%;
      }
    }
    @media screen and (min-width: 1110px) {
      display: flex;
      padding: 12rem 0 8rem 0;
    }
    .container {
      @media screen and (min-width: 768px) {
        display: flex;
        //flex: 1 1 100%;
      }
    }

    &-info {
      text-align: center;
      @media screen and (min-width: 768px) {
        text-align: left;
        flex-basis: 57%;
      }
      @media screen and (min-width: 1110px) {
        padding-top: 1em;
      }

      &-title {
        color: var(--blue-500);
        font-size: 2em;
        line-height: 39px;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        margin-bottom: 0.5em;
        @media screen and (min-width: 768px) {
          width: 135%;
        }
        @media screen and (min-width: 1110px) {
          width: 115%;
          font-size: 3.3em;
          line-height: 60px;
        }
      }

      &-description {
        font-size: 1em;
        line-height: 24px;
        font-family: var(--font-family-Dino-regular);
        color: var(--blue-500);
        margin-bottom: 1.1em;
        @media screen and (min-width: 1110px) {
          font-size: 1.33em;
          line-height: 27.12px;
        }
      }

      &-play-btn {
        color: var(--white) !important;
        width: 100%;
        text-transform: uppercase;
        line-height: 19px;
        font-size: 1em;
        max-width: 360px;
        letter-spacing: -0.17px;
        box-shadow: 0 10px 34px 0 rgba(23, 128, 224, 0.27);
        margin: 0 auto 1.7em auto;
        min-height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 768px) {
          margin: 0 0 1.7em 0;
          max-width: 379px;
        }
        @media screen and (min-width: 1110px) {
          max-width: 500px;
          font-size: 1.3em;
          line-height: 34px;
        }
      }
      &-shop {
        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-title {
          word-break: break-word;
        }
        &-img {
          background: var(--white);
          border-radius: var(--b-radius-round);
          height: 63px;
          width: 63px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 60px;
          & .Icon {
            max-width: 47px;
          }
        }
        &-details {
          @media (min-width: 768px) {
            max-width: 330px;
          }
          @media (min-width: 1110px) {
            max-width: 395px;
          }
        }
      }
      &-appStore,
      &-shop {
        text-align: center;
        @media screen and (min-width: 768px) {
          text-align: left;
          display: flex;
          align-items: center;
        }

        &-img {
          max-width: 123px;
          margin-bottom: 0.7em;
          @media screen and (min-width: 768px) {
            max-width: 144px;
            margin-right: 1.2em;
            margin-bottom: 0;
          }
          @media screen and (min-width: 1110px) {
            max-width: 180px;
          }
        }

        &-details {
          font-size: 0.85em;
        }

        &-title {
          color: var(--blue-500);
          line-height: 16px;
          @media (min-width: 1110px) {
            line-height: 24px;
            font-size: 1.2rem;
          }
          @media (min-width: 768px) {
            line-height: 22px;
            font-size: 1.1rem;
          }
        }

        &-description {
          //letter-spacing: -0.28px;
          color: #001500;
          line-height: 16px;
          font-family: var(--font-family-Dino-regular);
          @media screen and (min-width: 1110px) {
            font-size: 1.29em;
            line-height: 24px;
            width: 110%;
          }
          @media (min-width: 768px) {
            line-height: 19px;
          }
        }
      }
    }

    &-trailer {
      position: relative;
      text-align: center;
      & img {
        margin: 4em auto 0;
        max-height: 320px;
        width: auto;
        filter: drop-shadow(55px 25px 50px rgba(23, 128, 224, 0.3));
        @media (min-width: 400px) {
          max-height: 390px;
        }
        @media (min-width: 768px) {
          margin: 0 auto;
          max-height: 390px;
        }
        @media (min-width: 1110px) {
          max-height: 500px;
        }
      }

      &-watch {
        position: absolute;
        bottom: -8%;
        left: 48%;
        transform: translateX(-50%);
        text-align: center;
        @media screen and (min-width: 400px) {
          bottom: -4%;
          left: 47%;
        }
        @media screen and (min-width: 1110px) {
          bottom: 0;
          //left: 53%;
        }
      }

      &-description {
        color: var(--blue-500);
        line-height: 29.12px;
        font-size: 1em;
        font-family: var(--font-family-Dino-regular);
        @media screen and (min-width: 1110px) {
          font-size: 1.33em;
        }
      }

      &-btn {
        background: #ffffff;
        box-shadow: 0 2px 49px 0 rgba(23, 128, 224, 0.27);
        max-width: 76px;
        max-height: 76px;
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease;
        }

        @media screen and (min-width: 768px) {
          max-width: 55px;
          max-height: 55px;
        }
        @media screen and (min-width: 1110px) {
          max-width: 76px;
          max-height: 76px;
        }
      }
    }
  }

  .Wave-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/img/landingpage/top-background-wave@2x.png");
  }

  &-statistics {
    padding: 2.2em 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "item1 item1 item1 item1"
      "item2 item2 item2 item2"
      "item3 item3 item3 item3"
      "item4 item4 item4 item4";
    row-gap: 1em;
    @media screen and (min-width: 576px) {
      column-gap: 1em;
      grid-template-areas:
        "item1 item1 item2 item2"
        "item3 item3 item4 item4";
    }
    @media screen and (min-width: 1110px) {
      //padding-top: 4.2em;
      grid-template-areas: "item1 item2 item3 item4";
    }

    &-item {
      &-progressBar {
        width: 170px !important;
      }

      border-radius: 16px;
      min-height: 218px;
      padding: 1.3em 1em;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      //margin-bottom: 1em;
      &:nth-child(1) {
        grid-area: item1;
        background: #f75ea0;
      }

      &:nth-child(2) {
        grid-area: item2;
        background: #2f2fa2;
      }

      &:nth-child(3) {
        grid-area: item3;
        background: #b213cf;
      }

      &:nth-child(4) {
        grid-area: item4;
        background: #0cf79c;
      }

      &-count {
        font-size: 2.66em;
        color: #fff;
        line-height: 55px;
      }

      &-description {
        font-size: 1.2em;
        line-height: 21px;
        color: #fff;
        font-family: var(--font-family-Dino-regular);
      }

      &-boardTitle {
        text-align: center;
        color: var(--blue-500);
        font-size: 1.2em;
        line-height: 21px;
        margin-bottom: 1em;
        font-family: var(--font-family-Dino-regular);
      }

      &-boardList {
        list-style: none;
        width: 100%;
      }

      &-boardItem {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding-left: 2em;
        counter-increment: incr;
        background: #ffffff;
        color: var(--blue-500);
        font-weight: bold;
        padding: 0.3em 0.7em 0.3em 0.3em;
        box-shadow: 0 16px 30px 0 rgba(197, 236, 207, 0.3);
        border-radius: 100px;

        &:not(:last-child) {
          margin-bottom: 0.5em;
        }

        &-name {
          margin-right: auto;
          margin-left: 0.7em;
          line-height: 21px;
        }

        &:before {
          content: counter(incr);
          //position: absolute;
          border-radius: 50%;
          background: #d3f6dc;
          width: 1.7em;
          height: 1.7em;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &-features {
    background-image: url(../../assets/img/landingpage/backgroundFeaturesMobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 768px) {
      background-image: url(../../assets/img/landingpage/backgroundFeatures@2x.jpg);
    }
    & > .container {
      padding: 3em 1em 2em;
      @media (min-width: 1110px) {
        padding: 3em 0 2em 1em;
      }
    }
    &-header {
      margin-bottom: 2rem;
      @media (min-width: 1110px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
      }

      &-title {
        font-size: 2em;
        margin-bottom: 1.2rem;
        color: #000000;
        line-height: 35px;

        @media (min-width: 1110px) {
          width: 23%;
          font-size: 2.3em;
          line-height: 37px;
        }
      }

      &-description {
        color: var(--blue-500);
        line-height: 24px;
        font-size: 1em;
        font-family: var(--font-family-Dino-regular);
        @media (min-width: 768px) {
          font-size: 1.2em;
          line-height: 26px;
        }
        @media (min-width: 1110px) {
          width: 65%;
          //font-size: 2.3em;
          //line-height: 37px;
        }
      }
    }

    &-list {
      display: grid;
      grid-template-columns: 30% 30% 30% 1fr;
      grid-row-gap: 1.6em;
      grid-template-areas:
        "item1 item1 item1"
        "item2 item2 item2"
        "item3 item3 item3"
        "item4 item4 item4"
        "item5 item5 item5"
        "item6 item6 item6";
      @media (min-width: 676px) {
        grid-column-gap: 1em;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
          "item1 item1 item2 item2"
          "item3 item3 item4 item4"
          "item5 item5 item6 item6";
      }
      @media (min-width: 1110px) {
        grid-column-gap: 0;
        grid-template-columns: 34% 32% 34%;
        grid-template-rows: min(180px, 300px) 1fr min(250px, 300px);
        grid-auto-flow: row;
        grid-template-areas:
          "item1 item7 item2"
          "item3 item7 item4"
          "item5 item7 item6";
        padding-bottom: 6em;
      }

      &-item {
        @media (min-width: 676px) {
          display: flex;
        }
        circle {
          fill: #fff;
        }
        &-slider {
          .slick-img {
            height: 700px;

            align-items: center;
            & img {
              //max-height: 100%;
              width: 100%;
              margin: 0 auto;
            }
          }
        }

        &:nth-child(1) {
          grid-area: item1;
        }

        &:nth-child(2) {
          grid-area: item2;
        }

        &:nth-child(3) {
          grid-area: item3;
        }

        &:nth-child(4) {
          grid-area: item5;
        }

        &:nth-child(5) {
          grid-area: item6;
          grid-row: span 2;
        }

        &:nth-child(6) {
          grid-area: item7;
          max-width: 380px;
          display: none;
          @media (min-width: 1110px) {
            display: block;
          }
        }

        &-img .Icon {
          max-width: 83px;
          max-height: 83px;
        }

        &-details {
          margin-left: 0.8em;
          @media (min-width: 768px) {
            margin-left: 1.2em;
          }
          @media (min-width: 1110px) {
            margin-left: 0.4em;
          }

          &-head {
            color: var(--blue-500);
            font-size: 1.6em;
            line-height: 21px;
            margin-bottom: 0.4em;
            @media (min-width: 768px) {
              line-height: 26px;
              font-size: 1.3em;
            }
            @media (min-width: 1110px) {
              //width: 110%;
              line-height: 26px;
              font-size: 1.5em;
            }
          }

          &-description {
            color: var(--blue-500);
            line-height: 24px;
            font-size: 1em;
            font-family: var(--font-family-Dino-regular);
            @media (min-width: 768px) {
              line-height: 26px;
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }

  &-About {
    &-background:nth-child(1) {
      background-image: url("../../assets/img/landingpage/brand-background@2x.png");
    }
    &-background:nth-child(2) {
      background-image: url("../../assets/img/landingpage/school-background@2x.png");
    }
    &-background:nth-child(3) {
      background-image: url("../../assets/img/landingpage/enterprise-background@2x.png");
    }
    &-background {
      background-size: cover;
      background-repeat: no-repeat;
      &:not(:last-child) {
        margin-bottom: 2em;
      }

      .About-Wrapper {
        min-height: 460px;
        .WelcomePage-About-content-image {
          min-width: 310px;
          @media (min-width: 676px) {
            width: 34%;
          }
          @media (min-width: 1110px) {
            width: 42%;
          }
          & picture,
          & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        @media (min-width: 1110px) {
          width: 92%;
        }
        @media (min-width: 1440px) {
          max-width: 1440px;
          margin: 0 auto;
        }
      }
    }

    &-background:nth-child(even) {
      .About-Wrapper {
        margin-left: auto;
        flex-direction: row-reverse;
        //padding: 2em 0;
      }
      .WelcomePage-About-content-image {
        @media (min-width: 676px) {
          margin-left: 1.3rem;
        }
      }
      .WelcomePage-About-content-text {
        @media (min-width: 1441px) {
          padding-left: 9% !important;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
          padding-left: 2% !important;
        }
        @media (min-width: 768px) {
          padding-left: 1rem;
          padding-right: 1.5em;
        }
        @media (min-width: 1110px) {
          padding-right: 5.5em;
        }
      }
    }
    &-background:nth-child(odd) {
      .About-Wrapper {
        margin-right: auto;
      }
      .WelcomePage-About-content-text {
        @media (min-width: 1441px) {
          padding-right: 9% !important;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
          padding-right: 2% !important;
        }
        @media (min-width: 768px) {
          padding-right: 1rem;
          padding-left: 1.5em;
        }
        @media (min-width: 1110px) {
          padding-left: 5.5em;
        }
      }
      .WelcomePage-About-content-image {
        @media (min-width: 676px) {
          //margin-right: 1.3rem;
        }
      }
    }
    &-content {
      color: #fff;
      @media (min-width: 676px) {
        display: flex;
        justify-content: space-between;
      }

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        //margin-bottom: 1em;
      }

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //max-width: 750px;
        padding: 1.5rem 1em 2.2em 1em;

        @media (min-width: 1440px) {
          width: 56%;
        }
        @media (min-width: 768px) {
          width: 59%;
        }
        @media (min-width: 1110px) {
          width: 62%;
        }
        h3 {
          font-family: var(--font-family-Dino-bold);
          font-size: 1.6em;
          margin-bottom: 1rem;
          @media (min-width: 1110px) {
            font-size: 1.8rem;
          }
        }

        & > p {
          font-size: 1rem;
          margin-bottom: 1.1rem;
          font-family: var(--font-family-Dino-regular);
          line-height: 1.6rem;
          @media (min-width: 768px) {
            line-height: 1.73rem;
          }
          @media (min-width: 1110px) {
            font-size: 1.2rem;
          }
        }
      }

      &-findMore {
        display: none;
        align-items: center;

        & .Icon {
          padding-top: 0.75rem;
          max-width: 19px;
          max-height: 18px;
          path {
            fill: #fff;
          }
        }

        & > div {
          font-size: 1em;
          font-family: var(--font-family-Dino-regular);
          cursor: pointer;
          transition: transform 0.2s ease;
          position: relative;

          &:hover + .Icon {
            transform: translateX(0.2rem);
            transition: transform 0.2s ease;
          }

          &:hover::after {
            width: 100%;
            transition: width 0.3s ease;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -0.5em;
            left: 0;
            height: 3px;
            background: #fff;
            width: 0;
            transition: width 0.3s ease;
          }
        }
      }
    }
  }

  &-Trusted {
    padding-top: 3.5em;
    padding-bottom: 3.5em;
    //background-image: linear-gradient(-138deg, #F8FCFF 0%, #EAF3FA 100%);

    .title {
      color: #000;
      font-size: 2rem;
      line-height: 35px;
      text-align: center;
      margin-bottom: 2em;
      @media (min-width: 768px) {
        font-size: 2.2rem;
        line-height: 37.54px;
      }
    }

    &-icon {
      display: block;
      max-width: 110px;
      height: auto;
      margin-bottom: 1em;
      @media (min-width: 1110px) {
        min-width: 170px;
        margin-bottom: 0;
      }
      @media (min-width: 768px) {
        min-width: 150px;
      }
    }

    &-icons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 92%;
      margin: 0 auto;
      flex-wrap: wrap;
      @media (min-width: 1110px) {
        justify-content: space-between;
        max-width: 96%;
      }
    }
  }
}

@keyframes animContainer {
  from {
    height: 0;
    transition: height ease;
  }
  to {
    height: 350px;
    transition: height ease;
  }
}
@keyframes animListItems {
  from {
    transform: translateX(-100px);
    opacity: 0;
    transition: all ease;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    transition: all ease;
  }
}
