.Modal-container {
  padding: 0 1em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 55;
  background: var(--semi-transparent-black-200);
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    width: calc(100% - 50px);
    max-width: 800px;
    min-height: 100px;
    background: var(--main-200);
    border-radius: 16px;
    box-shadow: 0 0 15px 3px var(--semi-transparent-black-100);
  }
}
.slideUp {
  animation: .3s slideUpIn ease;
}
.slideUp-exit {
  animation: .39s slideUpOut ease;
}
@keyframes slideUpOut {
  from {
    top: 50%;
  }
  to {
    top: -100%;
  }
}
@keyframes slideUpIn {
  from {
    top: -100%;
  }
  to {
    top: 50%;
  }
}
