@import "fonts";
@import "placeholders";
@import "buttons";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  background-color: var(--main-200);
  color: var(--contrast-0);
  font-size: 15px;
  font-family: var(--base-font-family);
  line-height: 146%;
}

h1 {
  font-size: 1.8666rem;
}
h2 {
  font-size: 1.3333rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.86666rem;
}
h5 {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
  }
}

ul {
  list-style-type: none;
}

input {
  //background-color: transparent;
  color: var(--black-300);
  border: 1px solid var(--contrast-0);
  border-radius: 6px;
  padding: 0.75em;

  &:disabled {
    border: 1px solid var(--contrast-0);
    background-color: var(--contrast-0);
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    border: 1px solid var(--contrast-0);
  }

  &::placeholder {
    color: var(--contrast-0);
    font-style: italic;
    font-weight: 600;
  }

  &.themed {
    border: 1px solid var(--accent-200);
    &::placeholder {
      color: var(--accent-300);
    }
  }

  &.empty {
    background-color: transparent;
    border-color: var(--contrast-0);
    border-width: 1px;
    padding: 0.5em;
    font-size: 0.9em;
    letter-spacing: 0.1em;

    &::placeholder {
      font-weight: 500;
    }
  }
}
.notification {
  width: 17px;
  height: 17px;
  border-radius: 35px;
  background-color: var(--error-color);
  border: 1px solid var(--contrast-0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65em;
  font-style: normal;
  font-weight: bold;
  color: var(--contrast-0)
}

.empty-block {
  font-size: var(--font-400);
  @extend %bordered-block;
  padding: 1em 3em;
  margin: 1em auto;
  @media (max-width: 576px) {
    padding: 1em 1em;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &:hover {
    background-color: var(--semi-transparent-black-200);
  }
  @extend %hotFadeInOutAnimation;
}

.Auth-loader {
  width: 64px;
  margin: 1.5em auto 0;

  & circle {
    fill: #700B81
  }

  & path {
    stroke: #700B81;
  }
}

.trailer-wrapper {
  height: 90vh;
  padding: 1em !important;
  @media (min-width: 768px) {
    max-height: 450px;
  }
  @media (max-width: 767px) {

    max-height: 350px;
  }
  @media (max-width: 468px) {

    max-height: 250px;
  }
}
