@import "../../assets/styles/placeholders";

.AppInvitation {
  font-family: var(--font-family-Dino-bold);
  text-align: center;
  &-title {
    color: var(--contrast-0);
    font-size: 2em;
    line-height: 1.3em;
    @media (max-width: 568px) {
      font-size: 1.8em;
    }
  }
  &-btn {
    margin: 0 auto;
    @extend %purple-button
  }
  &-image {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 524px;
    max-width: 600px;
    margin: 0 auto;
    min-width: 220px;
  }
}
