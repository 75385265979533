
.Notification {
  min-width: 300px;
  max-width: 450px;
  width: auto;
  margin: .5em 1em .5em .5em;
  border-radius: 5px;
  position: relative;
  padding: 2em;
  box-shadow: 2px 2px 5px 0 var(--semi-transparent-black-300);
  font-family: var(--font-family-Dino-bold);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 7px;
  }

  &.success {
    background-color: var(--white);
  }
  &.warning {
    background-color: var(--white);
  }
  &.error {
    background-color: var(--white);
  }
  &.info {
    background-color: var(--white);
  }
  &.success &-title {
    color: var(--black-400);
  }
  &.success:before{
    background-color: var(--green-350);
  }
  &.info:before{
    background-color: var(--blue-200);
  }

  &.info &-title {
    color: var(--black-400);
  }

  &.warning:before{
      background-color: var(--red-0);
  }

  &.warning &-title {
    color: var(--black-400);
  }

  &.error:before {
    background-color: var(--red-200);
  }
  &.error &-title {
    color: var(--black-400);
  }

  &-title {
    font-weight: 900;
    font-size: 1.2em;
    margin-bottom: .5em;
    letter-spacing: 1px;
  }

  &-message {
    font-weight: 500;
    color: var(--black-400);
  }

  &-close {
    position: absolute;
    right: .5em;
    top: 1em;

    svg {
      cursor: pointer;
      fill: var(--black-400);
      &:hover {
        fill: var(--red-200);
      }
    }
  }
}
